<script setup lang="ts">
import { AddToObservedError, RemoveFromObservedError } from '~/constants/errors'

const props = defineProps<{
  productId?: string
  productVariantId?: string
}>()

const emit = defineEmits<{
  (e: 'toggleWishlist', payload: { productId?: string, productVariantId?: string, isWishlisted: boolean }): void
}>()

const { t } = useI18n()
const { addToWishlist, isWishlisted, removeFromWishlist } = useObserved()
const isItemWishlisted = ref(props.productId ? isWishlisted(props.productId) : false)
const isAnimating = ref(false)
const alert = ref('')

const loggedIn = computed(() => useAuth()?.loggedIn.value === true)
const signInPromptOpen = ref(false)

function onSignInPromptOpen(open: boolean) {
  if (loggedIn.value)
    return
  signInPromptOpen.value = open
}

function handleWishlistClick() {
  if (!loggedIn.value || !props.productId || !props.productVariantId) {
    // Handle not logged in state (e.g., show login prompt)
    return
  }

  isAnimating.value = true
  const newWishlistState = !isItemWishlisted.value

  // Optimistically update UI
  isItemWishlisted.value = newWishlistState

  // Perform API call
  const toggleWishlist = newWishlistState
    ? addToWishlist(props.productVariantId)
    : removeFromWishlist(props.productId)

  toggleWishlist
    .then(() => emit('toggleWishlist', { ...props, isWishlisted: newWishlistState }))
    .catch((error) => {
      // Revert on error
      isItemWishlisted.value = !newWishlistState
      if (error instanceof AddToObservedError || error instanceof RemoveFromObservedError) {
        alert.value = error.message
      }
    })
    .finally(() => {
      // End animation after API call completes
      setTimeout(() => {
        isAnimating.value = false
      }, 500) // Match this with animation duration
    })
}

watch(alert, (value) => {
  if (value) {
    setTimeout(() => {
      alert.value = ''
    }, 5000)
  }
})
</script>

<template>
  <UiDialog
    :open="signInPromptOpen"
    @update:open="onSignInPromptOpen"
  >
    <UiDialogTrigger as-child>
      <slot v-bind="{ isItemWishlisted, handleWishlistClick, isAnimating, alert }" />
    </UiDialogTrigger>
    <UiDialogContent>
      <UiDialogTitle class="sr-only">
        {{ t('save_to_wishlist') }}
      </UiDialogTitle>
      <LazySignInPrompt v-if="signInPromptOpen" />
    </UiDialogContent>
  </UiDialog>
</template>
